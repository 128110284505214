<template>
  <div>
    <VasionButton
      id="more"
      :classProp="'secondary-grey'"
      :ripple="true"
      @vasionButtonClicked="ellipsisOpen($event)"
    >
      More
    </VasionButton>

    <VasionEllipsisMenu
      v-if="showEllipsisMenu"
      v-click-outside="ellipsisClose"
      :buttonConfig.sync="ellipsisButtonConfig"
      :rowItem="{}"
      :style="ellipsisMenuTopStyle"
      @ellipsis-button-clicked="ellipsisButtonClicked"
      @close="ellipsisClose"
    />
  </div>
</template>

<script>
export default {
  name: 'More',
  props: {
    disableButtons: {
      type: Array,
      required: false,
      default: () => [false, false, false, false],
    },
    hideButton: {
      type: Array,
      required: false,
      default: () => [false, false, false, false],
    },
  },
  data: function () {
    return {
      ellipsisButtonConfig: {
        buttons: [
          {
            id: 'schedule-report',
            name: 'Schedule',
            icon: 'VasionReleaseIcon',
            divider: true,
            isDisabled: this.disableButtons[0],
            hideButton: this.hideButton[0],
          },
          {
            id: 'edit-report',
            name: 'Edit',
            icon: 'VasionEditIcon',
            isDisabled: this.disableButtons[1],
            hideButton: this.hideButton[1],
          },
          {
            id: 'share-report',
            name: 'Share',
            icon: 'VasionShareIcon',
            divider: true,
            isDisabled: this.disableButtons[2],
            hideButton: this.hideButton[2],
          },
          {
            id: 'delete-report',
            name: 'Delete',
            icon: 'VasionDeleteIcon',
            isDisabled: this.disableButtons[3],
            hideButton: this.hideButton[3],
          },
        ],
      },
      ellipsisMenuTopStyle: '',
      showEllipsisMenu: false,

    }
  },

  watch: {
    disableButtons() {
      this.ellipsisButtonConfig.buttons.forEach((b, i) => {
        if (!b.buttonPermissionDenied) {
          b.isDisabled = this.disableButtons[i]
        }
      })
    },
  },
  methods: {
    ellipsisButtonClicked(payload) {
      switch (payload.buttonId) {
        case 'share-report':
          this.$emit('shareClicked')
          break;
        case 'edit-report':
          this.$emit('editClicked')
          break;
        case 'schedule-report':
          this.$emit('scheduledClicked')
          break;
        case 'delete-report':
          this.$emit('deleteClicked')
          break;
        default:
          break;
      }
      this.ellipsisClose()
    },
    ellipsisClose() {
      this.showEllipsisMenu = false
    },
    ellipsisOpen(event) {
      this.ellipsisClose()
      let displayY = event.pageY
      let baseSize = 0

      if (this.ellipsisButtonConfig && this.ellipsisButtonConfig.buttons) {
        baseSize = this.ellipsisButtonConfig.buttons.length * 40
      }

      if (displayY + baseSize > document.documentElement.scrollHeight) {
        displayY -= baseSize
      }
      if (this.$route.name === 'TheSavedWorkflowReport') {
        displayY += 60
      }

      this.ellipsisMenuTopStyle = `top:${displayY}px;`
      this.showEllipsisMenu = true
    },
  },
}
</script>

<style>

</style>
