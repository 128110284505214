<template>
  <div class="vap-page">
    <div class="vap-page-header">
      <h1>{{ workflowReport.name }}</h1>
      <div class="btns-container">
        <More
          :disableButtons="[false, false, false, false]"
          :hideButtons="[canRunWorkflowReport, false, canRunWorkflowReport, false]"
          @shareClicked="generatePdf()"
          @editClicked="editReportClick()"
          @scheduledClicked="scheduleReport()"
          @deleteClicked="deleteReport()"
        />
        <VasionButton
          v-if="canRunWorkflowReport"
          id="run-report-button"
          :classProp="'primary'"
          @vasionButtonClicked="generateReport()"
        >
          Run Report
        </VasionButton>
      </div>
    </div>
    <div v-if="workflowReportTableData.tableHeaders" class="vap-page-content">
      <div v-if="workflowReportTableData.tableRows.length > 0" id="no-results-div" class="vasion-html-table-default">
        {{ workflowReportSummary }}
        <VasionTable id="report-table" :headerColumns="workflowReportTableData.tableHeaders" :tableRows="workflowReportTableData.tableRows" />
      </div>
      <div v-else>
        No data for the given report configuration
      </div>
    </div>
    <div v-else class="center spacer vap-page-content">
      <VasionEmptyReportsIcon />
      <div class="vasion-large-message">
        Run a Report to view entries
      </div>
    </div>
    <md-dialog id="confirmLeave" :md-active.sync="showDeleteDialog" @md-clicked-outside="cancelDelete()">
      <VasionConfirmationDialog
        message="Are you sure you want to delete this report?"
        @noButtonClick="cancelDelete()"
        @yesButtonClick="doDelete()"
      />
    </md-dialog>
    <VasionSnackbar
      id="delete-folder-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import More from '@/components/reporting/More.vue'

export default {
  name: 'TheSavedWorkflowReport',
  components: {
    Loading,
    More,
  },
  data: function () {
    return {
      canRunWorkflowReport: false,
      isLoading: false,
      loaderBackgroundColor,
      loaderColor,
      showDeleteDialog: false,
      showSnackbarBool: false,
      snackbarImage: '',
      snackbarSubTitle: '',
      snackbarTitle: '',
      workflowReport: {},
    }
  },
  computed: {
    workflowReportTableData() { return this.$store.state.reporting.workflowReport },
  },
  watch: {
    workflowReport: function () { this.$store.dispatch('reporting/resetReports') },
  },
  async created() {
    this.canRunWorkflowReport = this.$store.getters['common/canRunWorkflowReport']
    this.workflowReport = await this.$store.dispatch('reporting/getSavedWorkflowReport', this.$route.params.workflowReportId || this.$route.params.reportId)
  },
  methods: {
    cancelDelete() { this.showDeleteDialog = !this.showDeleteDialog },
    deleteReport() { this.showDeleteDialog = !this.showDeleteDialog },
    async doDelete() {
      const response = await this.$store.dispatch('reporting/deleteWorkflowReport', this.workflowReport.workflowReportID)
      if (response.Value === 'True') {
        this.showSnackbarBool = true
        this.snackbarImage = true
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'Report has been deleted'

        await this.$store.dispatch('reporting/getAllSavedWorkflowReports', true)
        this.$store.dispatch('reporting/loadReports')
        this.$router.push({ name: 'TheEmptyReport' })
      }
    },
    async editReportClick() {
      this.$store.dispatch('reporting/setEditingWorkflowReport', true)
      await this.$store.dispatch('reporting/setWorkflowReportData', this.workflowReport)
      this.$router.push({ name: 'TheWorkflowReport' })
    },
    async generatePdf() {
      this.isLoading = true
      const payload = {
        IndexFormID: this.workflowReport.indexFormID,
        ActionDateFrom: this.workflowReport.actionDateFrom,
        ActionDateThrough: this.workflowReport.actionDateThrough,
        LastWFID: this.workflowReport.lastWFID,
        UserIDs: this.workflowReport.actionUserIDs,
        SearchFields: Object.values(this.workflowReport.searchFields),
      }
      const response = await this.$store.dispatch('reporting/generateWorkflowReportPDF', payload)
      if (!response) {
        this.snackbarImage = false
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'There may be no data to share.'
        this.showSnackbarBool = true
      }
      await this.$amplitude.trackEvent('Report Shared', {
        'reportType': 'Workflow',
      })
      this.isLoading = false
    },
    generateReport: async function () {
      this.isLoading = true

      const payload = {
        IndexFormID: this.workflowReport.indexFormID,
        ActionDateFrom: this.workflowReport.actionDateFrom,
        ActionDateThrough: this.workflowReport.actionDateThrough,
        LastWFID: this.workflowReport.lastWFID,
        UserIDs: this.workflowReport.actionUserIDs,
        SearchFields: Object.values(this.workflowReport.searchFields),
      }
      await this.$store.dispatch('reporting/runWorkflowReport', payload)

      this.isLoading = false
    },
    async scheduleReport() {
      await this.$store.dispatch('reporting/setScheduledReportName', this.workflowReport.name)
      this.$router.push({ name: 'EditReportScheduledReporting' })
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .center {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .header {
    display: flex;
    flex-direction: row;
  }

  .buttons {
    flex-wrap: wrap;
  }

  .spacer {
    margin-top: 75px;
  }
</style>
